import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';

import Link from 'next/link'
import { useRouter } from 'next/router';

import useMobile from '../Hooks/Mobile';
import useDevice from '../Hooks/Device';
import eventGTM from '../../lib/gtag';

import SideMenu from '../SideMenu';
import SubMenuItems from '../SubMenuItems';

import links from '../../helper/links';
import { 
  whatWeDoItems, qaWhatWeDoItems,
  learnItems, qaLearnItems
} from '../SubMenuItems/menuItems'

import styles from './styles.module.scss';
import NevoLogo from '../../public/assets/images/nevo-advisory-logo'
import Hamburger from '../../public/assets/icons/hamburger';

const headerHeights = [
  { big: 153, small: 132 }, // 1 item
  { big: 197, small: 169 }, // 2 items
  { big: 236, small: 203 }, // 3 items
  { big: 284, small: 243 }, // 4 items
  { big: 329, small: 280 }, // 5 items
  { big: 369, small: 317 }, // 6 items
]

// boolean:
// transparent - removes the backround
// black - fades the background with black color
// blackStyle - blackFont leaves the font black in any case
interface IHeader {
  transparent?: boolean;
  noShadowBox?: boolean;
  black?: boolean;
  blackStyle?: boolean;
  renderHeader?: boolean;
}

const Header = ({transparent = false, noShadowBox = false, black = false, blackStyle = false, renderHeader = true }: IHeader) => {
  const [displayMenu, setDisplayMenu] = useState(false);

  const [headerAnimation, setHeaderAnimation] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0)
  const [changeScroll, setChangeScroll] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);
  
  const [displaySubWhat, setDisplaySubWhat] = useState(false);
  const [displaySubLearn, setDisplaySubLearn] = useState(false);
  const [displayTelematics, setDisplayTelematics] = useState(false);
  const [displayEnergy, setDisplayEnergy] = useState(false);

  const router = useRouter();
  const mobile = useMobile();
  const device = useDevice();

  const headerRef = useRef<HTMLDivElement>(null);

  const currentPath = router.asPath.split('/')[1];

  const [WhatWeDoItems, whatWeDoCount]: any = SubMenuItems({ items: whatWeDoItems, qaItems: qaWhatWeDoItems, eventType: 'Header' });
  const [LearnItems, learnCount]: any = SubMenuItems({ items: learnItems, qaItems: qaLearnItems, eventType: 'Header' });

  // Adapt Header Height on Hover
  const adaptHeaderHeight = useCallback(() => {
    if (headerRef && headerRef.current) {
      let targetHeight;
      
      const size = window.innerWidth > 1440 ? 'big' : 'small' 

      targetHeight = 
        displaySubLearn ? headerHeights[learnCount][size]
        : displaySubWhat ? headerHeights[whatWeDoCount][size]
        : ''

      if (typeof targetHeight === 'number') headerRef.current.style.height = `${targetHeight}px`;
      else headerRef.current.style.height = '';        
    }
  }, [displaySubLearn, displaySubWhat, learnCount, whatWeDoCount]);

  useEffect(() => {
    adaptHeaderHeight();

    window.addEventListener('resize', adaptHeaderHeight);
    return () => window.removeEventListener('resize', adaptHeaderHeight);
  }, [adaptHeaderHeight]);
  // End of Adaptation


  // Fix and hide header on scroll
  const fixHeader = useCallback(() => {
    const scrollY = window.scrollY;
    
    // Adding Fixed Header Style
    if (scrollY === 0 && fixedHeader) {
      setFixedHeader(false);
    }
    else if (scrollY > 30 && !fixedHeader) setFixedHeader(true);

    
    // Hiding/Showing Header
    
    if (!headerAnimation) {
      if (prevScrollY > scrollY) { // on scroll up, always updated change scroll
        setChangeScroll(scrollY);
      }

      if ((prevScrollY > scrollY || scrollY === 0) && hideHeader) {
        setHeaderAnimation(true)
  
        setTimeout(() => {
          headerRef?.current?.classList.remove(styles.showHeader);
          setHideHeader(false)
  
          setHeaderAnimation(false)
        }, 490);

        headerRef?.current?.classList.add(styles.showHeader);
      }

      // hide header only after scrolling 100 pixels down, from prev scroll
      else if (scrollY > prevScrollY && !hideHeader && scrollY > changeScroll + 100) {
        setHeaderAnimation(true)
      
        setTimeout(() => {
          headerRef?.current?.classList.remove(styles.hideHeader);
          setHideHeader(true);
  
          setHeaderAnimation(false);
        }, 490);

        headerRef?.current?.classList.add(styles.hideHeader);

        setChangeScroll(scrollY);
      }

      setPrevScrollY(scrollY);
    }
  }, [fixedHeader, headerAnimation, hideHeader, prevScrollY, changeScroll]);

  useEffect(() => {
    fixHeader()

    window.addEventListener('scroll', fixHeader);
    return () => window.removeEventListener('scroll', fixHeader);
  }, [fixHeader])

  const isDisplaySubNav = useMemo(() => {
    return displaySubWhat || displaySubLearn
  }, [displaySubLearn, displaySubWhat]);

  useEffect(() => {
    const htmlDoc = document.children[0] as HTMLElement;
    
    if (isDisplaySubNav) {
      htmlDoc.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    }
    else { 
      htmlDoc.style.overflow = "";
      document.body.style.overflow = "";
    }

  }, [isDisplaySubNav])

  const openSideMenu = () => {
    const htmlDoc = document.children[0] as HTMLElement;
    htmlDoc.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    eventGTM('clickedOnHamburgerMenu')

    setDisplayMenu(true);
  }

  const hideSubNav = () => {
    setDisplaySubWhat(false)
    setDisplaySubLearn(false)
    setDisplayTelematics(false)
    setDisplayEnergy(false)
  }

  const headerStyle = styles.headerContainer 
    + (fixedHeader ? ` ${styles.fixedHeader}` : "")
    + (isDisplaySubNav ? ` ${styles.headerContainerOpenned}` : "")
    + (transparent ? ` ${styles.transparentHeader}` : "")
    + (black ? ` ${styles.blackHeader}` : "")
    + (blackStyle ? ` ${styles.blackStyle}` : "")
  ;
  
  const renderNavButtons = () => {
    return (
      <>
        <div className={styles.navButton}>
          <span 
            className="nav-button default-cursor"
            onMouseEnter={() => {
              hideSubNav();
              setDisplaySubWhat(true);
            }}
            onClick={() => eventGTM("whatWeDoHeader")}
          >
            What we do
          </span>
          
          {
            <p className={
              `${styles.coolUnderline} ` + (
                (currentPath.match("fleet-transition|charging-solutions|energy-management|emobility-education") && !isDisplaySubNav) || displaySubWhat ? styles.displayUnderline : ""
              )
            }>&nbsp;</p>
          }          

          { displaySubWhat && 
            <div className={styles.subNavButtonHolder}>
              { WhatWeDoItems }
            </div> 
          }
        </div>

        <div className={styles.navButton}>
          <span 
            className="nav-button"
            onMouseEnter={() => {
              hideSubNav();
              setDisplayTelematics(true)
            }} 
            onClick={() => eventGTM("telematicsHeader")}
          >
            <Link href={links.telematics}>Telematics</Link>
          </span>

          {
            <p className={
              `${styles.coolUnderline} ` + (
                (currentPath.includes('telematics') && !isDisplaySubNav && !displayEnergy) || displayTelematics ? styles.displayUnderline : ""
              )
            }>&nbsp;</p>
          }
        </div>
        
        <div className={styles.navButton}>
          <span 
            className="nav-button"
            onMouseEnter={() => {
              hideSubNav();
              setDisplayEnergy(true)
            }} 
            onClick={() => eventGTM("energyHeader")}
          >
            <Link href={links.energy}>Energy</Link>
          </span>

          {
            <p className={
              `${styles.coolUnderline} ` + (
                (currentPath === 'energy' && !isDisplaySubNav && !displayTelematics) || displayEnergy ? styles.displayUnderline : ""
              )
            }>&nbsp;</p>
          }
        </div>

        {/* <div className={styles.navButton}>
          <span 
            className="nav-button default-cursor"
            onMouseEnter={() => {
              hideSubNav();
              setDisplaySubLearn(true);
            }}
          >
            Learn
          </span>
          
          {
            <p className={
              `${styles.coolUnderline} ` + (
                (currentPath.match("learn|insights|studies") && !isDisplaySubNav) || displaySubLearn ? styles.displayUnderline : ""
              )
            }>&nbsp;</p>
          }

          { displaySubLearn && 
            <div className={styles.subNavButtonHolder}>
              { LearnItems }
            </div>
          }
        </div> */}

        {/* Nevo Web */}
        <div className={styles.navButton + " " + styles.navCharging}>
          <span 
            className={`nav-button ${styles.navChargingSpan}`}
          >
            <a 
              className={styles.navChargingA} 
              onClick={() => eventGTM("nevoieHeader")}
              href={links.nevo_web}
              target='_blank' rel='noreferrer'
            >
              Nevo.ie
            </a>
          </span>
        </div>
      </>
    );
  }

  if (!renderHeader) return <></>

  return (
    <>
      <div 
        ref={headerRef}
        className={headerStyle}
        onMouseLeave={() => {
          hideSubNav();
        }}
        style={{ 
          display: hideHeader ? "none" : "",
          boxShadow: noShadowBox ? "none" : ""
        }}
      >
        <Link href={links.home} passHref>
          <div 
            className={`nevo-logo ${styles.nevoLogo}`}
            onMouseEnter={() => {
              hideSubNav();
            }}
          >
            <NevoLogo 
              color={(isDisplaySubNav || fixedHeader) ? "#1A1C1F" 
                : blackStyle ? "#1A1C1F" 
                : transparent ? "white" : "#1A1C1F"
              }
            />
          </div>
        </Link>
        
        { (mobile === false && device === "Desktop") &&
          <nav className={styles.desktopNav}>
            { renderNavButtons() }
          </nav>
        }

        <div className={styles.hamburgerHolder}>
          <div 
            className={styles.hamburgerIcon} 
            onMouseEnter={() => {
              hideSubNav();
            }}
            onClick={openSideMenu}
          >
            <Hamburger 
              color={(isDisplaySubNav || fixedHeader) ? "#1A1C1F" 
                : blackStyle ? "#1A1C1F" 
                : transparent ? "white" : "#1A1C1F"
              }
            />
          </div>
        </div> 
      </div>

      {
        isDisplaySubNav && <div className="fading-cover">&nbsp;</div>
      }

      {
        displayMenu && <SideMenu hideMenu={() => setDisplayMenu(false)} />
      }
    </>
  )
}

export default Header;